// Blue
$blue-50: #edfaff;
$blue-100: #d6f2ff;
$blue-200: #b5e9ff;
$blue-300: #83deff;
$blue-400: #48c9ff;
$blue-500: #1eaaff;
$blue-600: #068cff;
$blue-700: #0078ff;
$blue-800: #085bc5;
$blue-900: #0d509b;

/* Colors */
// Green
$green-50: #f0fdf4;
$green-100: #dcfce7;
$green-200: #bbf7d0;
$green-300: #86efac;
$green-400: #4ade80;
$green-500: #22c55e;
$green-600: #16a34a;
$green-700: #15803d;
$green-800: #166534;
$green-900: #14532d;

// Grayscale
$grayscale-0: #ffffff;
$grayscale-50: #f8fafc;
$grayscale-100: #f1f5f9;
$grayscale-150: #e9eef4;
$grayscale-200: #e2e8f0;
$grayscale-300: #cbd5e1;
$grayscale-400: #94a3b8;
$grayscale-500: #64748b;
$grayscale-600: #475569;
$grayscale-700: #334155;
$grayscale-800: #1e293b;
$grayscale-900: #0f172a;

// Others
$red: #ff5252;
$orange: #ff971d;
$yellow: #ffda44;
$violet: #ab53ff;
$pink: #ff52a7;
