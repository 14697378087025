@import "src/styles/variables";

body {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: $grayscale-900;
}

/* Headlines */

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
}

h2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
}

h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 115%;
}

/* Description */

.f-description {
  &-1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
  }

  &-2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
  }

  &-semibold {
    font-weight: 400;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
  }
}

/* Text */

.f-regular {
  &-1 {
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
  }

  &-2 {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
  }

  &-3 {
    font-weight: 400;
    font-size: 11px;
    line-height: 110%;
  }
}

.f-medium {
  &-1 {
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
  }

  &-2 {
    font-weight: 500;
    font-size: 12px;
    line-height: 120%;
  }
}

.f-semibold {
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
}

/* Label */

.f-label {
  &-small {
    font-weight: 500;
    font-size: 11px;
    line-height: 105%;
  }

  &-large {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
  }
}

/* Numbers */

.f-numbers {
  font-weight: 600;
  font-size: 9px;
  line-height: 130%;
}

.f-price {
  display: inline-flex;
  gap: 3px;

  .f-currency {
    align-self: flex-start;
    font-weight: 600;
    font-size: 9px;
    line-height: 1;
  }
}

/* Link */

.f-link {
  cursor: pointer;
  user-select: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/* Others */

.f-recommendation {
  font-weight: 500;
  font-size: 11px;
  line-height: 105%;
  letter-spacing: -0.03em;
  color: $blue-700;
  cursor: pointer;
  user-select: none;

  &.disabled {
    color: $grayscale-500;
    cursor: default;
    pointer-events: none;
  }
}
