@import "src/styles/variables.scss";
@import "src/styles/mixins";

body {
  background: $grayscale-100;
  @include overflow;

  &:has(.cdk-overlay-container:not(:empty)) {
    overflow: hidden !important;
  }

  &.auth {
    background: $grayscale-0;
  }

  app-root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  height: 48px;
  background-color: rgba($grayscale-400, 0.5);
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 8px;
}

cdk-dialog-container {
  position: fixed;
  z-index: 999;
  overflow-y: auto;
}

.cdk-virtual-scrollable {
  @include overflow;
}
